import React from 'react';
import '../css/CostOptimization.css';
import '../css/HeroSection.css';

function CostOptimization() {
    return(
        <div className='opt-container flex-column'>
            <div className='opt-header'>
                CLOUD COST MANAGEMENT & OPTIMIZATION SERVICES
            </div>

            <div className='opt-text'>
                We review and reduce your cloud spending and enhance business requirements without compromising the growth
                and cut unnecessary spending by identifying wasted resources and maximizing return on investment (ROI) for every dollar/pound/euro/rupee spent in public cloud
            </div>

            <div className='opt-aws'>
                    <div className='opt-aws-details'>
                        <div className='opt-aws-item'>Cost Optimization Analysis</div>
                        <div className='opt-aws-item'>Savings Action Plan</div>
                        <div className='opt-aws-item'>Cost Reduction</div>
                    </div>
                    <div className='opt-aws-details-right'>
                        <div className='opt-aws-item-right'>Cloud Usage Monitoring</div>
                        <div className='opt-aws-item-right'>Optimizing Cloud Architecture</div>
                        <div className='opt-aws-item-right'>Cloud Budget, Cost & Usage reports</div>
                    </div>
                {/*<table className='opt-aws-details'>*/}
                    {/*<tbody>*/}
                        {/*<tr>*/}
                            {/*<td className='opt-row' style={{paddingBottom: '9%'}}>Cost Optimization Analysis</td>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                            {/*<td className='opt-row' style={{paddingBottom: '9%'}}>Savings Action Plan</td>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                            {/*<td className='opt-row' style={{paddingBottom: '9%'}}>Cost Reduction</td>*/}
                        {/*</tr>*/}
                    {/*</tbody>*/}
                {/*</table>*/}
                {/*<div style={{paddingLeft: '25%'}}></div>*/}

                {/*<table className='opt-aws-details'>*/}
                    {/*<tbody>*/}
                        {/*<tr>*/}
                            {/*<td className='opt-row'>Cloud Usage Monitoring</td>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                            {/*<td className='opt-row'>Optimizing Cloud Architecture</td>*/}
                        {/*</tr>*/}
                        {/*<tr >*/}
                            {/*<td className='opt-row'>Cloud Budget, Cost & Usage reports</td>*/}
                        {/*</tr>*/}
                        {/*</tbody>*/}
                {/*</table>*/}



            </div>
            {/*<div className='hero-search flex-column'>*/}
                {/*<div className='hero-search-text'>Optimize your spends now</div>*/}
                {/*/!*<hr/>*!/*/}
                {/*<div className='flex-row hero-search-text-box-container'>*/}
                    {/*/!*<input type='text' placeholder='Name*'/>*!/*/}
                    {/*/!*<input type='text' className='hero-search-text-box' placeholder='Contact Number*'/>*!/*/}
                    {/*/!*<input type='text' className='hero-search-text-box' placeholder='Business Email*'/>*!/*/}
                    {/*/!*<input type='text' className='hero-search-text-box' placeholder='Your Message*'/>*!/*/}
                    {/*/!*<button className='hero-search-text-box hero-btns'>Submit</button>*!/*/}
                    {/*Please send us an email with your Business Email, Contact Number and details to finopsglobal@gmail.com*/}
                    {/*/!*<input type='button' className='hero-btns'>Submit</input>*!/*/}
                {/*</div>*/}
            {/*</div>*/}
        </div>
    )

}

export default CostOptimization;