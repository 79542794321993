import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import '../css/Navbar.css';
import Logo from './Logo';

function Navbar1() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if (window.innerWidth <= 1100) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    useEffect(() => {
        showButton();
    }, []);

     window.addEventListener('resize', showButton);

    return (
        <>
            <nav className='navbar'>
                <div className='navbar-container'>
                    <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
                        <img className='fab fa-typo3' src="test2.jpg" style={{width : '30%'}}></img>
                        {/*<div className='navbar-logo-up'>*/}
                            {/*<div >*/}
                                {/*<Logo/>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                        <span><b>Cloud FinOpsGlobal & <br/>Consulting Services Inc.</b></span>

                    </Link>
                    <div className='menu-icon' onClick={handleClick}>
                        <img className={click ? 'fas fa-times' : 'fas fa-bars'} src={click ? 'xicon.png': 'menubar.svg'}></img>
                        {/*<img className='fa-bars' src='menubar.svg'></img> already commented*/}
                    </div>
                    <ul className={click? 'nav-menu-active': 'nav-menu'}>
                    {/*<ul className='nav-menu'>*/}
                        <li className='nav-item'>
                            <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                                 Cloud FinOps
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                to='/cost'
                                className='nav-links'
                                onClick={closeMobileMenu}
                            >
                                   Cost Optimization
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                to='/services'
                                className='nav-links'
                                onClick={closeMobileMenu}
                            >
                                 Managed Services
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                to='/consulting'
                                className='nav-links'
                                onClick={closeMobileMenu}
                            >
                                Consulting
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                to='/training'
                                className='nav-links'
                                onClick={closeMobileMenu}
                            >
                                Training
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                to='/careers'
                                className='nav-links'
                                onClick={closeMobileMenu}
                            >
                                Careers
                            </Link>
                        </li>

                        <li className='nav-item'>
                            <Link to='/about' className='nav-links' onClick={closeMobileMenu}>
                                About
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/contact' className='nav-links' onClick={closeMobileMenu}>
                                Contact
                            </Link>
                        </li>
                    </ul>
                    {/*{button && <Button buttonStyle='btn--outline'>SIGN UP</Button>}*/}
                </div>
            </nav>
        </>
    );
}

export default Navbar1;