import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import '../css/Navbar.css';
import {Button} from "./Button";

function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);
    const handleClick = () => setClick(!click);

    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if(window.innerWidth <=960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    useEffect(() => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return(
        <>
            <nav className="navbar">
                <div className="navbar-container">
                    <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
                        Cloud Finops GSC Inc
                        <img className='fab fa-typo3' src="logo.jpeg" style={{width : '5%'}}></img>
                    </Link>
                    <div className='menu-icon' onClick={handleClick}>
                        <img className={click ? 'fas fa-times' : 'fas fa-bars'} src={click ? 'xicon.png': 'menubar.svg'}></img>
                    </div>

                <ul className={click? 'nav-menu-active': 'nav-menu'}>
                    <li className='nav-item'>
                        <Link to="/" className="nav-links" onClick={closeMobileMenu}>
                        Solutions
                        </Link>

                    </li>
                    <li className='nav-item'>
                        <Link to="/services" className="nav-links" onClick={closeMobileMenu}>
                        Cloud Finops
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to="/products" className="nav-links" onClick={closeMobileMenu}>
                        Cloud Trainings
                        </Link>
                    </li>
                    {/*<li className='nav-item'>*/}
                        {/*<Link to="/products" className="nav-links" onClick={closeMobileMenu}>*/}
                        {/*Cloud Services*/}
                        {/*</Link>*/}
                    {/*</li>*/}
                    <li className='nav-item'>
                        <Link to="/products" className="nav-links" onClick={closeMobileMenu}>
                        About Us
                        </Link>
                    </li>
                </ul>
                    {/*{button && <Button buttonStyle='btn--outline'> SIGN UP</Button>}*/}
                </div>
            </nav>
        </>
    )

}

export default Navbar;

