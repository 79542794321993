import React from 'react';
import '../css/HeroSection.css';

function HeroSection() {

    return(
        <div className='hero-container'>
            {/*className='hero-section'*/}
            {/*<div>*/}
                {/*<video src="homepage.mp4" autoPlay loop muted></video>*/}
            {/*</div>*/}
            {/*<div className='hero-text'>*/}
                {/*Test*/}
            {/*</div>*/}

                <div className='hero-video'>
                    <video src="homepage.mp4" autoPlay muted></video>
                </div>
                <div className='hero-search flex-column'>
                    <div className='hero-search-text'></div>
                    {/*<hr/>*/}
                    <div className='flex-row hero-search-text-box-container'>

                        {/*//!*<input type='text' placeholder='Name*'/>*!/*/}
                        {/*<input type='text' className='hero-search-text-box' placeholder='Contact Number*'/>*/}
                        {/*<input type='text' className='hero-search-text-box' placeholder='Business Email*'/>*/}
                        {/*<input type='text' className='hero-search-text-box' placeholder='Your Message*'/>/}
                        {/*<button className='hero-search-text-box hero-btns'>Submit</button>*/}
                        {/*<input type='button' className='hero-btns'>Submit</input>*/}

                    </div>
                </div>

        </div>

    )
}

export default HeroSection;