import './App.css';
import Navbar from "./components/Navbar";
import Navbar1 from "./components/NavBar1";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import HeroSection from "./components/HeroSection";
import CostOptimization from './components/CostOptimization';
import ManagedServices from './components/ManagedServices';
import Consulting from './components/Consulting';
import Training from './components/Training';
import Careers from './components/Careers';
import AboutUs from './components/AboutUs'
import Contact from './components/Contact'
import Footer from "./components/Footer";
import Test from "./components/Test";

function App() {
  return (
    <div className="app">
        <Router>
            {/*<Test/>*/}
            <Navbar1/>
            <Routes>
                <Route path="/" exact element={<HeroSection/>}>
                </Route>
                <Route path="/cost" exact element={<CostOptimization/>}>
                </Route>
                <Route path="/services" exact element={<ManagedServices/>}>
                </Route>
                <Route path="/consulting" exact element={<Consulting/>}>
                </Route>
                <Route path="/training" exact element={<Training/>}>
                </Route>
                <Route path="/careers" exact element={<Careers/>}>
                </Route>
                <Route path="/about" exact element={<AboutUs/>}>
                </Route>
                <Route path="/contact" exact element={<Contact/>}>
                </Route>
            </Routes>
            {/*<Footer/>*/}
        </Router>
    </div>
  );
}

export default App;
