import React from 'react';
import '../css/ManagedServices.css';


function ManagedServices() {
    return(
        <div className='ms-container'>
            <div className='ms-main-header'>
                MANAGED MULTI-CLOUD SERVICES
            </div>
            <div className='ms-text'>
                <b>With our FinOps Global support, your teams are empowered to deliver more value with greater agility</b>
            </div>
            <div className='ms-aws'>
                <div className='ms-details'>
                    <div className='ms-row'>Managed Cloud Landing Zone</div>
                    <div className='ms-row'>Managed Cloud Environment</div>
                    <div className='ms-row'>Managed Cloud Migrations</div>
                </div>
                <div className='ms-details-right'>
                    <div className='ms-row-right'>Cloud Cost Governance/Savings/Optimization</div>
                    <div className='ms-row-right'>Cloud IT Monitoring and Support Engineer- 24/7</div>
                    <div className='ms-row-right'>Managed FinOps</div>
                </div>
                {/*<table className='ms-details' style={{width: '30%', paddingTop: '1%'}}>*/}
                    {/*<tbody>*/}
                    {/*<tr>*/}
                        {/*<td className='ms-row' style={{paddingBottom: '10%'}}>Managed Cloud Landing Zone</td>*/}
                    {/*</tr>*/}
                    {/*<tr>*/}
                        {/*<td className='ms-row' style={{paddingBottom: '10%'}}>Managed Cloud Environment</td>*/}
                    {/*</tr>*/}
                    {/*<tr>*/}
                        {/*<td className='ms-row' style={{paddingBottom: '10%'}}>Managed Cloud Migrations</td>*/}
                    {/*</tr>*/}
                    {/*</tbody>*/}
                {/*</table>*/}
                {/*<div style={{paddingLeft: '20%'}}></div>*/}
                {/*<table className='ms-details'>*/}
                    {/*<tbody>*/}
                    {/*<tr>*/}
                        {/*<td className='ms-row'>Cloud Cost Governance/Savings/Optimization</td>*/}
                    {/*</tr>*/}
                    {/*<tr>*/}
                        {/*<td className='ms-row'>Managed FinOps</td>*/}
                    {/*</tr>*/}
                    {/*<tr >*/}
                        {/*<td className='ms-row'>Cloud IT Monitoring and Support Engineer- 24/7</td>*/}
                    {/*</tr>*/}
                    {/*</tbody>*/}
                {/*</table>*/}
            </div>
            {/*<div className='ms-header'>*/}
                {/*<table className='ms-table'>*/}
                    {/*<tbody>*/}
                    {/*<tr>*/}
                        {/*<td className='ms-row'>1. Cloud capacity planning and TCO calculation services</td>*/}
                    {/*</tr>*/}
                    {/*</tbody>*/}
                {/*</table>*/}
                {/*<div style={{paddingLeft: '5%'}}>*/}
                {/*</div>*/}
                {/*<table className='ms-table' style={{width: '28%'}}>*/}
                    {/*<tbody>*/}
                    {/*<tr>*/}
                        {/*<td className='ms-row'>2. Cloud architecture review/Audit/Design/Implementation services</td>*/}
                    {/*</tr>*/}
                    {/*</tbody>*/}
                {/*</table>*/}
                {/*<div style={{paddingLeft: '5%'}}>*/}
                {/*</div>*/}
                {/*<table className='ms-table' style={{width: '25%'}}>*/}
                    {/*<tbody>*/}
                    {/*<tr>*/}
                        {/*<td className='ms-row'>3. Cloud cost optimization services</td>*/}
                    {/*</tr>*/}
                    {/*</tbody>*/}
                {/*</table>*/}
            {/*</div>*/}
            {/*<div className='ms-header'>*/}
                {/*<table className='ms-table' style={{width: '30.8%'}}>*/}
                    {/*<tbody>*/}
                    {/*<tr>*/}
                        {/*<td className='ms-row'>4. Cloud Manage backups and recovery</td>*/}
                    {/*</tr>*/}
                    {/*</tbody>*/}
                {/*</table>*/}
                {/*<div style={{paddingLeft: '5%'}}>*/}
                {/*</div>*/}
                {/*<table className='ms-table' style={{width: '28%'}}>*/}
                    {/*<tbody>*/}
                    {/*<tr>*/}
                        {/*<td className='ms-row'>5. Cloud security implementation services</td>*/}
                    {/*</tr>*/}
                    {/*</tbody>*/}
                {/*</table>*/}
                {/*<div style={{paddingLeft: '5%'}}>*/}
                {/*</div>*/}
                {/*<table className='ms-table' style={{width: '25%'}}>*/}
                    {/*<tbody>*/}
                    {/*<tr>*/}
                        {/*<td className='ms-row'>6. Cloud Monitoring and Support Engineer- 24/7</td>*/}
                    {/*</tr>*/}
                    {/*</tbody>*/}
                {/*</table>*/}
            {/*</div>*/}



                {/*<div>Cloud capacity planning and TCO calculation services</div>*/}
                {/*<div>Cloud architecture review/Audit/Design/Implementation services</div>*/}
                {/*<div>Cloud cost optimization services</div>*/}
                {/*<div>Cloud Manage backups and recovery</div>*/}
                {/*<div>Cloud security implementation services</div>*/}
                {/*<div>Cloud Monitoring and Support Engineer- 24/7  </div>*/}
        </div>
    )
}

export default ManagedServices;