import React from 'react';
import '../css/Careers.css';

function Careers() {
    return(
        <div className='careers-main'>
            <div className='careers-logo-container'>
                <img src="newlogo.jpg" style={{width : '108%'}}></img>
            </div>
            <div className='careers-container'>
                <div className='careers-header'>Careers @ Cloud FinOps Global</div>
                <hr/>
                <div className='careers-text' style={{paddingTop: '6%', paddingBottom: '2%'}}>​BEGIN YOUR JOURNEY WITH US</div>

                <div className='careers-text'>
                    The world is witnessing digital transformation with technology entirely shaping business operations, business models, industries and outcomes. At Cloud FinOps Global, we nurture trust and engagement, which leads to stellar individuals and team performances. Whether you’re just beginning your career or ready to take your career to the next level, become a part of our team and contribute to building a better future. <b>info@finopsglobal.com</b>
                </div>
            </div>
        </div>

    );

}

export default Careers;