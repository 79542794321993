import React from 'react';
import '../css/Contact.css';

function Contact() {
    return(
        <div className='contact-main'>
            <div className='contact-logo-container'>
                <img src="newlogo.jpg" style={{width : '108%'}}></img>
            </div>
            <div className='contact-container'>
                <div className='contact-header'>
                    Contact Us
                </div>
                <hr/>
                <div className='contact-text'>
                    Please send us an email to  <b>info@finopsglobal.com</b> with your details and we will be happy to assist you.
                </div>
                <div className='address-text'>
                    <b>Address:</b>
                </div>
                <div className='contact-text'>
                    CLOUD FINOPSGLOBAL & CONSULTING SERVICES INC,<br/>
                    6 MONTICELLO AVE,<br/>
                    MONROE, NJ 08831.
                </div>
            </div>

        </div>
    );

}

export default Contact;