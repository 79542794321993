import React from 'react';
import '../css/Consulting.css';

function Consulting() {
    return(
        <div className='consulting-container'>
            <div className='consulting-header'>CLOUD CONSULTING SERVICES</div>
            <div className='consulting-sub-header'>Tech Talent Nationwide</div>
            <div className='consulting-sub-title'>
                We have been providing the best consulting services in cloud technology since 2020.
                Our unique methodology processes and resources Evaluate, Design, Implement and Manage that fit your business needs with a quick turnaround.
            </div>
            <div className='consulting-aws'>
                <div className='consulting-aws-details'>
                    <div className='consulting-row'>Cloud Cost Optimzation</div>
                    <div className='consulting-row'>Cloud FinOps Governance</div>
                    <div className='consulting-row'>Cloud Capacity Planning</div>
                </div>
                <div className='consulting-aws-details-right'>
                    <div className='consulting-row-right'>Cloud Infrastructure Strategy  & Design</div>
                    <div className='consulting-row-right'>Cloud Design Implementation</div>
                    <div className='consulting-row-right'>Cloud Infrastructure Review & Audits</div>
                </div>
                {/*<table className='consulting-aws-details'>*/}
                    {/*<tbody>*/}
                    {/*<tr>*/}
                        {/*<td className='consulting-row' style={{paddingBottom: '10%'}}>Cloud Cost Optimzation</td>*/}
                    {/*</tr>*/}
                    {/*<tr>*/}
                        {/*<td className='consulting-row' style={{paddingBottom: '10%'}}>Cloud FinOps Governance</td>*/}
                    {/*</tr>*/}
                    {/*<tr>*/}
                        {/*<td className='consulting-row' style={{paddingBottom: '10%'}}>Cloud Capacity Planning</td>*/}
                    {/*</tr>*/}
                    {/*</tbody>*/}
                {/*</table>*/}
                {/*<div style={{paddingLeft: '28%'}}></div>*/}

                {/*<table className='opt-aws-details'>*/}
                    {/*<tbody>*/}
                    {/*<tr>*/}
                        {/*<td className='consulting-row'>Cloud Infrastructure Strategy  & Design </td>*/}
                    {/*</tr>*/}
                    {/*<tr>*/}
                        {/*<td className='consulting-row'>Cloud Design Implementation</td>*/}
                    {/*</tr>*/}
                    {/*<tr >*/}
                        {/*<td className='consulting-row'>Cloud Infrastructure Review & Audits</td>*/}
                    {/*</tr>*/}
                    {/*</tbody>*/}
                {/*</table>*/}

                {/*<div className='opt-aws-details' style={{paddingLeft: '40%'}}>*/}
                {/*<div>Amazon S3 Analytics</div>*/}
                {/*<div>AWS Cost and Usage Report</div>*/}
                {/*<div>Aws Compute optimizer</div>*/}
                {/*<div>AWS Instance Scheduler</div>*/}
                {/*</div>*/}

            </div>

        </div>
    )

}

export default Consulting;