import React from 'react';
import '../css/Training.css';

function Training() {
    return(
        <div className='training-main'>
            <div className='training-logo-container'>
                <img src="newlogo.jpg" style={{width : '108%'}}></img>
            </div>
            <div className='training-container'>
                <div className='training-header'>
                    Courses Offered
                </div>
                <div className='training-content-container'>
                    <div className='training-content-header'>AWS Cost Optimization -- Online Training</div>
                    <hr/>
                    <div className='training-details'>
                        <b>Course Details: </b>
                        <a href="Training.pdf" download="Finops Global Training">
                            <img src="pdfIcon.jpeg" style={{width: '30px'}} alt="W3Schools"/>
                        </a>
                    </div>
                    <div className='training-duration'><b>Duration:</b> 5hrs</div>
                    <div className='training-duration'><b>Location:</b> Virtual </div>
                    <div className='training-instruction'><b>Corporate Trainings</b> : Email - info@finopsglobal.com</div>
                </div>

                <div className='training-content-container' style={{paddingTop: '6%'}}>
                    <div className='training-content-header'>AWS Administration -- Online Training</div>
                    <hr/>
                    <div className='training-details'>
                        <b>Course Details: </b>
                        <a href="Training.pdf" download="Finops Global Training">
                            <img src="pdfIcon.jpeg" style={{width: '30px'}} alt="W3Schools"/>
                        </a>
                    </div>
                    <div className='training-duration'><b>Duration: </b>8 hrs</div>
                    <div className='training-duration'><b>Location:</b> Virtual </div>
                    <div className='training-instruction'><b>Corporate Trainings</b> : Email - info@finopsglobal.com</div>
                </div>
            </div>

        </div>
    );

}

export default Training;