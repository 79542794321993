import React from 'react';
import '../css/AboutUs.css'

function AboutUs() {
    return(
        <div className='aboutus-main'>
            <div className='aboutus-logo-container'>
                <img src="newlogo.jpg" style={{width : '108%'}}></img>
            </div>
            <div className='aboutus-container'>
                <div className='aboutus-header'>About Us</div>
                <hr/>
                <div className='aboutus-text'>
                    Cloud FinOps Global enables individuals and enterprises to reimagine their businesses for the ever-changing digital world. Established in 2022, we are a leading IT services and consulting company in NA along with a global footprint across ​Indian markets. <br/> <br/>
                    Our belief in the constant technological evolution in this ever-changing digital landscape and our long-lasting relationships with our customers makes us a trusted and preferred business partner in many of our client’s digital transformation. We offer an integrated portfolio of world-class solutions for Product Engineering & Development to Industry Verticals like Multi Media, Telecom, Manufacturing and Healthcare.
                </div>
                <div className='aboutus-header'>
                    <b> Core Values </b>
                </div>
                <hr/>
                <div className='aboutus-text'>
                    Our core values shape the entire culture and defines our personality. It makes us who we are! These bred-in-the bone values serve as an underpinning in how we act and make decisions, giving us a direction and a sense of purpose. This human-centered approach towards technology is the benchmark of what all we do. <br/>
                    Being a dynamic and reliable business partner Transforming businesses through passion, innovation, and industry expertise Creating value through our multiple IT solution possibilities Core Values.
                </div>
            </div>
        </div>


    );

}

export default AboutUs;